


import VRPTImg from '../../../images/VRforPTThumbnail.jpg';





export const VRPT = {
    lightText: true,
    lightTextDesc: true,
    headline: 'The Software',
    description: 'Minigames were developed in C# using Microsoft Visual Studio. The game engine used was Unity 3D version 2019.2.17fl and Android SDK/NDK for development from Android 4.4 (KitKat) which is API level 19. All software was developed in real time and used the Oculus Quest which had an Android 7.1.1 OS',
    imgStart: true,
    img: VRPTImg,
    alt: 'VR for Physical Therapy Case Study picture',
    dark: true,
    primary: true,
    darkText: false,

    aosAnimation: "fade-left",
};