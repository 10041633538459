

import React from 'react';
import InfoSec from '../components/InfoSec';
import {VRPT} from '../components/InfoSec/Data Sets/VRPTDataSet';
import {SectionContainer,MainHeading} from '../components/SectionElements';
import '../App.css';

const VRPTCaseStudy = () => {
    return (
       <>
     <SectionContainer className = 'rich-black'>
        <MainHeading className='cultured-white'>Past Work</MainHeading>
          
            <InfoSec {...VRPT}/>
         
            
           
    </SectionContainer>
        </>
    )
}

export default VRPTCaseStudy

